.block-control,
.not-front #webform-client-form-1989,
.field-name-field-rsvp form {
  width: 100%;
  margin-bottom: 78px;
  padding: 30px 65px;
  position: relative;
  @include box-shadow;
}

.field-name-field-rsvp form {
  margin-bottom: 200px;
}
.not-front #webform-client-form-1989,
.field-name-field-rsvp form {
  padding: 30px 180px 30px 65px;
}



.front .block-webform h3:first-child {
  display: none;
}

.scrolled div#block-block-5 {
  display: none;
}

div#block-block-5 {
  position: absolute;
  right: 43px;
  top: -7px;
  @media (max-width: 480px) {
    right: auto;
    top: 26px;
    left: 20px;
  }

  a {
      font-size: 12px;
      color: #fff;
      font-weight: normal;
  }
}

div#block-block-6 {
  float: left;
  width: 100%;
  clear: both;
  padding: 40px 40px 10px;
  background: #efefef;
  margin: 0 0 40px;

  p {
    float: left;
  }

  a.download {
    padding: 15px 0px;
    background: #ef0325;
    color: #fff;
    font-size: 18px;
    font-family: "fira-sans",sans-serif;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 15px 30px;
    text-align: center;
    display: inline-block;
    float: right;
    margin: -20px 20px 20px;
  }
}

#block-pfcd-blocks-intro, #block-pfcd-blocks-above-footer {
  width: 100%;
  position: relative;
  z-index: 3;
  margin-top: -333px;

  .intro-left {
    width: 660px;
    height: 457px;
    display: block;
    float: left;
    @include box-shadow;
    background-color: $gray-1;

    .intro-wrapper {
      width: 50%;
      height: 100%;
      float: left;
      padding: 27px 29px;
      position: relative;

      .intro-icon {
        height: 80px;
        overflow: hidden;
        background-image: url(/sites/all/themes/pfcd/assets/img/mental-health_v2.png);
        background-repeat: no-repeat;
        background-position: -10px;
      }

      h3 {
        margin: 10px 0;
        color: $font-red;
      }

      .description {

        p {
          font-size: $f18;
          line-height: $f22;
          font-family: "source-sans-pro";
          font-weight: 600;
          margin-top: 0;
        }
      }

      > a {
        @include button;
        padding: 15px 0px;
        width: 80%;
        width: calc(100% - 58px);
        display: block;
        position: absolute;
        text-align: center;
        bottom: 35px;
      }
    }

    img {
      width: 50%;
      float: left;
      height: 100%;
    }
  }

  .intro-right {
    width: 320px;
    height: 457px;
    display: block;
    float: left;
    margin-left: 20px;
    @include box-shadow;
    background-color: $panel-background;
    padding: 27px 29px;
    position: relative;

    .intro-icon {
      height: 77px;
      overflow: hidden;
      background-image: url("../img/homepage-sprite.png");
      background-repeat: no-repeat;
      background-position: 0px 0px;
      margin-bottom: 5px;
    }

    h3 {
      color: $white;
      margin-top: 16px;
      margin-bottom: 10px;
    }

    .secondary-text {
      color: $gray-2;
      font-size: $f18;
      line-height: $f22;
      font-family: "source-sans-pro";
      font-weight: 600;
    }

    .form-item {
      margin: 0;
    }

    select {
      @include button;
      text-transform: none;
      padding: 15px 20px;
      width: 80%;
      width: calc(100% - 58px);
      display: block;
      position: absolute;
      text-align: center;
      bottom: 52px;
      outline: none;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;

      &.opened {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      option {
        background-color: $gray-3;
        color: $black;
        font-family: "fira-sans";
        font-weight: 500;

        &:hover {
          background-color: transparent;
        }
      }

    }

    //IE 11
    select::-ms-expand {
      display: none;
    }

    .chosen-container {
      position: absolute;
      width: calc(100% - 58px) !important;
      @media (max-width: 1023px) {
        position: relative;
        bottom: -52px;
      }
      @media (max-width: 767px) {
        bottom: 0;
        max-width: none;
      }

      a.chosen-single {
        text-align: left;
        padding-left: 25px;
      }
    }

  }
}

#block-pfcd-blocks-above-footer {
  width: 66%;
  margin: 0;
  float: left;
  .intro-left {
    height: 487px;
    .intro-wrapper .intro-icon {
      background-image: url("../img/homepage-sprite.png");
      background-repeat: no-repeat;
      background-position: 0px -142px;
    }
  }
  @media (max-width: 1023px) {
    .intro-left {
      width: 100%;
      img {
        display: none;
      }
      .intro-wrapper {
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    .intro-left {
      width: 100%;
    }
  }
}

div#block-views-pfcd-in-the-states-block-1, div.state-video-block {
  width: 320px;
  display: inline-block;
  vertical-align: top;
  background: #2c2c2c;
  color: #fff;
  z-index: 4;

  .form-item {
    margin: 0;
  }

  .chosen-container {
    position: relative;
    bottom: 0;
    display: inline-block;

    .chosen-drop {
      position: absolute;
      min-width: 260px;
      width: 100%;
      z-index: 999;
    }

  }

  .view-header {
    font-size: 22px;
    font-family: "fira-sans";
    padding: 80px 30px 0px;
    background: url(../img/icon-statelocation.png) no-repeat 30px top;
    margin-bottom: 20px;
    p {
      color: #fff;
      font-size: 22px;
      margin: 0;
    }

  }

  .view-content {
    padding: 0 30px;
  }
}

div#block-views-pfcd-in-the-states-block-1 {
  width: 48%;
  margin: 30px 0 0 0;
  .view-header {
    padding: 0 30px 0px;
    p {
      font-size: 20px;
      padding-left: 91px;
    }
  }
  .view-content {
    padding: 0 30px 0 119px;
    form .chosen-container a {
    text-align: left;
    padding-left: 26px;
    }
  }
}

div.state-video-block, div#block-views-pfcd-in-the-states-block-1 {
  @media (max-width: 1023px) {
    width: 100%;
    display: block;
    margin-top: 40px;
    .mobile-hidden {
      display: none;
    }
  }
}

#block-views-hp-news-feed-block {
  width: 100%;
  position: relative;
  margin-top: 53px;
  margin-bottom: 68px;
  @include box-shadow;

  .views-row {
    $view-height: 400px;

    &.views-row-1  {
      width: 405px;
      height: $view-height;
      height: 440px;
      padding: 38px 65px 0;
      position: relative;
      background-color: $white;

      .views-field-body {
        max-height: 104px;
        overflow: hidden;
      }
    }

    &.views-row-2,
    &.views-row-3 {
      width: 530px;
      height: $view-height / 2;
      height: 220px;
      position: absolute;
      right: 65px;
      padding-left: 85px;
      padding-right: 20px;
      background-color: $gray-1;

      .views-field-view-node {
        display: none;
      }

      .views-field-title {
        position: relative;
        margin-bottom: 14px;
        &::before {
          content: "";
          background-image: url("../img/arrows-sprite.png");
          background-repeat: no-repeat;
          background-position: 0 -23px;
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          top: 3px;
          left: -35px;
          color: $font-red;
        }
      }
    }

    &.views-row-2 {
      padding-top: 38px;
      top: 0;
    }

    &.views-row-3 {
      bottom: 0;

      .views-field-title {
        padding-top: 38px;

        &:before {
          top: 41px;
        }
      }
    }

    .views-field-title {
      margin-bottom: 27px;
      a {
        font-family: "fira-sans";
        font-weight: 500;
        font-size: 22px;
        color: black;
      }
    }

    .views-field-body {
      color: $font-gray;
      font-size: 16px;
      font-family: "source-sans-pro";
      font-weight: 500;
    }

    .views-field-view-node {
      position: absolute;
      width: 69%;
      width: calc(100% - 130px);
      bottom: 60px;
      a {
        @include button;
        padding: 14px;
        text-align: center;
        width: 100%;
        display: block;
      }
    }

  }

  .view-more {
    position: absolute;
    width: 65px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: $gray-4;

    &:after {
      content: "";
      background-image: url("../img/arrows-sprite.png");
      background-repeat: no-repeat;
      background-position: 5px 2px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 20px;
      height: 20px;
      @include trans(0.2s);
    }

    a {
      position: absolute;
      height: 100%;
      width: 0px;
      right: 0;
      text-align: center;
      padding-top: 250px;
      text-transform: uppercase;
      background-color: $gray-4;
      overflow: hidden;
      @include trans(0.2s);

      &:after {
        content: "";

        background-image: url("../img/homepage-sprite.png");
        background-position: 0 -223px;
        background-repeat: no-repeat;
        position: absolute;
        top: 0; bottom: 25px; left: 0; right: 0; margin: auto;
        height: 70px;
        width: 85px;
      }

    }

    &:hover {
      a {
        width: 595px;
      }

      &:after {
        @include rotate(180deg);
      }
    }
  }
}

#block-poll-recent {
  @extend .block-control;

  .poll {
    overflow: initial;
    h3.title {
      display: inline;
    }

    span.description {
      font-size: 14px;
      color: $font-gray;
      font-family: "source-sans-pro";
      font-weight: 600;
      padding-left: 10px;
    }

    .warning-message {
      padding-left: 10px;
      color: $font-red;
      font-family: "source-sans-pro";
      font-weight: 600;
    }
    .vote-form {
      margin-top: 15px;
      .choices {
        width: 84%;
        width: calc(100% - 150px);
        float: left;

        #edit-choice {
          .form-item {
            width: 33%;
            float: left;
            padding-left: 20px;
            height: 145px;
            overflow: hidden;

            label {
              color: $font-gray;
              font-family: "source-sans-pro";
              font-weight: 600;
              line-height: 22px;
            }
          }
        }
      }

      > label {
        width: 150px;
        height: 100%;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        background-color: $red;
        @include trans(0.2s);

        &:hover {
          background-color: $red_hover;
        }

        input[type="submit"] {
          width: 100%;
          height: 100%;
          border-radius: 0;
          box-sizing: border-box;
          padding-top: 55px;
        }

        &::before {
          content: "";
          background-image: url("../img/homepage-sprite.png");
          background-repeat: no-repeat;
          background-position: 48px -74px;
          height: 70px;
          left: 0;
          position: absolute;
          top: 70px;
          width: 100%;
          cursor: pointer;
        }
      }

    }
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    clear: both;
  }
}

#block-pfcd-blocks-research {
  width: 100%;
  position: relative;
  margin-bottom: 65px;
  background-color: $white;
  @include box-shadow;

  .research-wrapper {
    float: left;
    padding: 36px 85px;
    width: 50%;

    .description {
      p {
        color: $font-gray;
        height: 130px;
        overflow: hidden;
      }
    }

    .download {
      @include button;
      width: 320px;
      position: absolute;
      bottom: 38px;
    }
  }

  img {
    float: right;
    width: 50%;
    height: auto;
  }
}

#block-pfcd-blocks-video {
  @include trans(0.3s);
  width: 66%;
  height: 375px;
  margin: 58px 0 0 0;
  float: left;
  @include box-shadow;
}

#block-views-issues-block,
#block-views-issues-block-1 {
  @include trans(0.3s);
  width: 32%;
  //width: 320px;
  // height: 475px;
  float: left;
  margin-left: 20px;
  margin-bottom: 60px;

  .view-issues {
    .views-row {
      @include box-shadow;
      @include trans(0.2s);
      width: 320px;
      height: 48px;
      position: relative;
      margin-bottom: 7px;
      @include trans(0.2s);

      a:after {
        content: "";
        // background-image: url("../img/about-sprite.png");
        background-repeat: no-repeat;
        height: 48px;
        width:  48px;
        position: absolute;
        right: 25px;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      // Issue colors
      @each $n, $color in $issue-reds {
        &.views-row-#{$n} {
          background-color: $color;
        }
      }

      // Issue hover colors
      @each $n, $color in $issue-reds-hover {
        &.views-row-#{$n}:hover {
          background-color: $color;
        }
      }

      .alzheimers-disease a:after {
        background-image: url("../img/alzheimers.png");
        right: 13px;
        top: 7px;
      }

      &.mental-health a:after {
        background-image: url("../img/mental-health.png");
        right: 13px;
        top: 7px;
      }

      &.cancer a:after {
        background-image: url("../img/cancer_v1.png");
        right: 13px;
        top: 5px;
      }

      &.diabetes a:after {
        background-image: url("../img/diabetes_v1.png");
        right: 13px;
        top: 2px;
      }

      &.heart-disease a:after {
        background-image: url("../img/heart_disease_v1.png");
        right: 17px;
        top: 10px;
      }

      &.obesity a:after {
        background-image: url("../img/obesity_v1.png");
        right: 16px;
        top: 7px;
      }

      &.asthma a:after {
        background-image: url("../img/asthma_v1.png");
        right: 19px;
        top: 5px;
      }

      &.arthritis a:after {
        background-image: url("../img/arthritis_v1.png");
        right: 13px;
        top: 7px;
      }

      &.chronic-migraines a:after {
        background-image: url("../img/chronicmigraines_v1.png");
        background-size: 100%;
        right: 13px;
        top: -1px;
      }

      .field-content {
        display: block;
        //height: 112px;
        line-height: 48px;
        padding-left: 25px;

        a {
          color: $white;
          font-size: 22px;
          text-transform: capitalize;
          height: 100%;
          width: 100%;
          display: block;
        }
      }
    }
  }
}

#block-views-latest-news-block-1 {
  width: 63%;
  height: 351px;
  float: left;
  background-color: $gray-1;
  padding: 40px 52px;
  @include box-shadow;

  a {
    //font-size: 26px;
  }

  .content {
    p {
      font-family: "ff-tisa-web-pro";
      font-size: 18px;
    }
  }
}

#block-pfcd-blocks-sidebar-media {
  width: calc(36% - 20px);
  height: 351px;
  margin-left: 20px;
  float: left;
  padding: 27px;
  background-color: $panel-background;
  font-family: "fira-sans";
  color: $white;
  position: relative;
  @include box-shadow;

  .sidebar-media {
    .media-icon {
      height: 77px;
      overflow: hidden;
      background-image: url("../img/homepage-sprite.png");
      background-repeat: no-repeat;
      background-position: 0px 0px;
      margin-bottom: 5px;
    }

    .sidebar-media-heading {
      font-size: $f22;
      color: $white;
      margin: 10px 0;
    }

    span {
      color: $font-gray;
    }

    a {
      @include button;
      position: absolute;
      bottom: 50px;
      width: 80%;
      width: calc(100% - 58px);
    }
  }

}

#block-views-resources-block-2 {
  float: left;
  width: 680px;
}

#block-pfcd-blocks-resources-state {
  float: left;
  width: 320px;
  height: 470px;
  background-color: $panel-background;
  position: absolute;
  padding: 27px 29px;
  margin-left: 10px;
  top: 20px;
  right: 10px;

  .resource-icon {
    height: 77px;
    overflow: hidden;
    background-image: url("../img/homepage-sprite.png");
    background-repeat: no-repeat;
    background-position: 0px 0px;
    margin-bottom: 5px;
  }

  .resources-state-heading {
    color: $white;
    margin-top: 16px;
    margin-bottom: 10px;
    font-family: "fira-sans";
    font-weight: 500;
    font-size: 22px;
  }

  .resources-state-description p {
    color: $gray-2;
    font-size: $f18;
    line-height: $f22;
    font-family: "source-sans-pro";
    font-weight: 600;
  }

  .chosen-container {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 260px !important;

    a {
      text-align: left;
      padding-left: 20px;
    }
  }
}

#block-block-4 {
  max-width: 980px;
  margin: 0 auto;
  position: relative;
    .block-content a {
      position: absolute;
      right: 20px;
      top: 220px;
      background: #ef0325;
      padding: 10px 20px;
      color: #fff;
      border-radius: 8px;
      font-size: 12px;
      @media (max-width: 767px) {
        left: 20px;
        right: auto;
      }

    }
  }

// Text treatment for FAQ <small> text

small {
  padding-top: 20px;
  display: block;
  a, a:visited, a:active, a:hover {
    color: #ef0325;
  }
}

#block-block-2, div.pfcd-states-middle {
  width: 100%;
  margin-right: 20px;
  display: inline-block;
  background: #f0f0f0;
  border-radius: 0;
  box-shadow: 0px 0px 10px #bbb;
  @media (max-width: 1023px) {
    width: 100%;
    display: block;
    h3 {
      top: -795px;
    }
  }

  h3 {
    font-family: "source-sans-pro";
    font-weight: 600;
    color: white;
    text-shadow: 1px 1px 5px black;
    font-size: 48px;
    width: 870px;
    margin: auto;
    position: absolute;
    top: -240px;
    // top: -564px;
    left: 0;
    right: 0;
  }

  .block-content {
    padding: 40px 50px;

    ul {
      margin: 0 0 30px 0;
      padding: 0;

      li {
        display: inline-block;
        padding: 0;
        margin: 20px 20px 0 0;
        @media (max-width: 1023px) {
          display: block;
          padding: 0;
          text-align: center;
          margin: 20px auto 40px;
        }

        a {
          padding: 15px 0px;
          background: #ef0325;
          color: #fff;
          font-size: 18px;
          font-family: "fira-sans", sans-serif;
          text-transform: uppercase;
          border-radius: 8px;
          padding: 15px 30px;
          text-align: center;
          display: block;
          @media (max-width: 1023px) {
            margin: 0 auto;
          }
        }
      }

      li + li {
        margin-right: 0;
      }
    }
    p {
      font-family: "ff-tisa-web-pro";
      font-size: 18px;
      font-weight: bold;

      strong {
        font-family: "fira-sans", sans-serif;
        text-transform: uppercase;
        font-size: 26px;
      }
    }
  }
}

div.pfcd-states-middle {
  width: 50%;
  margin: 30px 20px 0 0;
  background: #fff;
  box-shadow: none;
  @media (max-width: 1023px) {
    width: 100%;
    display: block;
  }
  h3:first-of-type {
    display: none;
  }
  .block-content {
    padding: 0;
    ul {
      margin: 0;
      li {
        margin: 11px 0 0 0;
        display: block;
      }
      li:first-of-type {
        margin: 0;
      }
    }
  }
}

#block-views-exp-states-news-page-1 {
  width: 340px;
  float: left;
  margin-top: 180px;

  .form-item {
    display: block;
  }

  #edit-tid-wrapper {
    width: 100%;

    .chosen-container {
      position: relative;
      margin: auto;
      // margin-top: 125px;
      width: 260px !important;
      bottom: auto;
      a {
        text-align: left;
        padding-left: 25px;
        width: 260px;
      }
    }
  }

  .views-widget > a {
    @include button;
    background-color: $panel-background;
    width: 260px;
    margin: 0 auto 20px;

    &:hover {
      background-color: $font-black-hover;
    }
  }


  .views-submit-button {
    width: 100%;
    margin-top: -200px;
    position: relative;
    a {
      @include button;
      background-color: $panel-background;
      width: 260px;
      margin: auto;

      &:hover {
        background-color: $font-black-hover;
      }
    }
  }

  #edit-submit-states-news {
    display: none;
  }
}

#block-pfcd-blocks-state-fact-sheet {
  width: 320px;
  height: 412px;
  display: block;
  float: left;
  @include box-shadow;
  background-color: $panel-background;
  padding: 27px 29px;
  position: relative;

  .fact-icon {
    height: 77px;
    overflow: hidden;
    background-image: url("../img/homepage-sprite.png");
    background-repeat: no-repeat;
    background-position: 0px 0px;
    margin-bottom: 5px;
  }

  h3 {
    color: $white;
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .description {
    p {
      color: $font-gray;
      font-size: $f18;
      line-height: $f22;
      font-family: "source-sans-pro";
      font-weight: 600;

    }
  }

  a.download {
    @include button;
    text-transform: none;
    padding: 15px 20px;
    width: 80%;
    width: calc(100% - 58px);
    display: block;
    position: absolute;
    text-align: center;
    bottom: 52px;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .back-link {
    a {
      @include button;
      margin-top: 195px;
      position: relative;
      width: 320px;
      margin-left: -30px;
    }
  }
}



.chosen-container {
  width: 80% !important;
  width: calc(100%) !important;
  height: 54px;
  bottom: 52px;

  &:after {
    content: "";
    background-image: url("../img/arrows-sprite.png");
    background-repeat: no-repeat;
    background-position: 2px -47px;
    position: absolute;
    top: 18px;
    right: 19px;
    color: $white;
    height: 21px;
    width: 21px;
    @include trans(0.3s);
  }

  a {
    height: 54px;
    width: 100%;
    display: block;
    padding-left: 20px;
    @include button;
    @include trans(0.2s);
  }

  .chosen-drop {
    input {
      display: none;
    }
    ul.chosen-results {
      display: block;
      background-color: $gray-3;
      padding: 0 20px;
      margin: 0;
      overflow: auto;
      height: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 0px;
      @include trans(0.2s);

      li {
        list-style: none;
        color: $black;
        font-family: "fira-sans";
        font-weight: 500;
        height: 40px;
        line-height: 40px;

        &.highlighted {
          cursor: pointer;
          text-decoration: underline;
        }

        &.disabled-result {
          display: none;
        }
      }

      //display: block !important;
    }
  }

  &.chosen-with-drop {
    &:after {
      //background-position: 0 -70px;
      @include rotate(180deg);
      background-position: 2px -45px;
    }

    a {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .chosen-drop {
      ul.chosen-results {
        height: 210px;
        padding-bottom: 10px;
      }
    }
  }
}

// Media queries

@media all and (max-width:1023px) {
  $page-width: 660px;

  body #container #page .main-wrap {
    width: $page-width;
    padding: 0;
  }

  #block-pfcd-blocks-intro {
            .intro-left {
              width: 50%;
              .intro-wrapper {
                width: 100%;
                .intro-icon {
                  background-position: center -142px;
                }
              }
              img {
                display: none;
              }
            }
            .intro-right {
              width: 50%;
              margin-left: 0;

              .intro-icon {
                background-position: center 0px;
              }
            }
          }

  #block-views-hp-news-feed-block {
     .views-row {
       padding-top: 20px;

       &.views-row-1 {
         width: 100%;
         height: initial;

         .views-field-view-node {
           bottom: 0;
           left: 0;
           right: 0;
           margin: 15px auto 30px;
           position: relative;
         }
       }

       &.views-row-2,
       &.views-row-3 {
         width: 90%;
         right: 0;
         position: relative;

         .views-field-title {
           padding-top: 0;
           &:before {
             top: 3px;
           }
         }

         .views-field-body {
           overflow: hidden;
           height: 63px;
         }
       }


     }

     .view-more {
       width: 10%;
       height: 386px; // double the height of views 2 and 3
       bottom: 0;
       top: initial;
       &:hover {
         a {
           width: 1000%;
         }
       }
     }
   }

  #block-pfcd-blocks-research {
    .research-wrapper {
      width: 100%;
      .download {
        margin: auto;
        bottom: 0;
        position: relative;
      }
    }

    img {
      display: none;
    }
  }

  #block-poll-recent {
    height: initial;
    padding: 30px 15px 1px 15px;

    .poll {
      .vote-form {
        .choices {
          width: 100%;
          float: none;

          #edit-choice {
            .form-item {
              width: 100%;
            }
          }
        }
        >label {
          position: relative;
          margin: 25px auto;
          height: 90px;
          width: 165px;
          border-radius: 10px;
          padding: 0 15px;
          box-sizing: initial;

          &:before {
            top: 10px;
            left: initial;
            right: 0;
            width: 117px;
          }


          input[type="submit"] {
            margin-top: 0;
            text-align: left;
            padding-top: 0;
          }
        }
      }
    }
  }

  #block-pfcd-blocks-video {
        iframe {
          width: 100%;
        }
      }

  #block-views-issues-block {
        width: 31px;
        width: calc(34% - 20px);

        .view-issues {
          .views-row {
            width: 100%;

            .field-content {
              padding-left: 15px;
              a {
                width: 65%;
                font-size: 17px;
                line-height: initial;
                vertical-align: middle;
                height: initial;
                display: inline-block;
              }
            }
          }
        }
      }

  #block-pfcd-blocks-resources-state {
    position: relative;
    width: 100%;
    height: initial;
    margin-bottom: 68px;

    .resource-icon {
      background-position: center 0px;
    }

    .resources-state-heading {
      text-align: center;
    }

    .resources-state-description {
      margin-bottom: 28px;
    }

    .resources-state-select {
      width: 100%;
      text-align: center;
    }
  }

  #block-views-latest-news-block-1 {
    width: 100%;
    margin-bottom: 68px;
  }

  #block-pfcd-blocks-sidebar-media {
    width: 100%;
    margin-left: 0;
  }

  #block-block-2 {
    h3 {
      width: 100%;
    }
  }

  #block-views-exp-states-news-page-1 {
    width: 100%;
    @include horizontal_background;
    background-image: none;
    margin-top: 0;

    &:before,
    &:after {
      background-image: none;
    }
  }
}

@media all and (max-width: 767px) {
  body #container #page .main-wrap {
    width: 100%;
    padding: 0 15px !important;
  }

  #block-pfcd-blocks-video {
    width: 100%;
  }

  #block-views-issues-block {
    margin-top: 68px;
    width: 100%;
    margin-left: 0;

    .view-issues {
      .views-row {
      }
    }
  }

  #block-pfcd-blocks-intro {
    .intro-left,
    .intro-right,
    .intro-wrapper {
      width: 100%;
      min-height: 457px;
      height: initial;

      h3 {
        text-align: center;
      }

      .description p,
      .secondary-text {
        margin-bottom: 100px;
      }

      .intro-wrapper > a,
      .chosen-container {
        //max-width: 260px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .chosen-container {
        max-width: none;
        min-width: 260px;
        width: 100%;

        a {
          max-width: none !important;
          width: 100%;
        }
      }
    }

    .intro-right {
      margin-top: 68px;
    }
  }

  #block-views-hp-news-feed-block {
    .views-row {
      padding: 15px !important;

      &.views-row-1 {
        .views-field-body {
          margin-bottom: 15px;
        }

        .views-field-view-node {
          padding: 0;
          margin: 0;
          width: 100%;
        }
      }

      &.views-row-2,
      &.views-row-3 {
        height: initial;
        width: 100%;
        .views-field-title:before {
          display: none;
        }
      }
    }
    .view-more {
      width: 100%;
      position: relative;
      height: 0px;

      &:after {
        display: none;
      }

      &:hover {
        a {
          position: relative;
          height: 50px;
          width: 100%;
          padding: 0;
          display: block;
          line-height: 50px;
          text-align: left;
          padding-left: 15px;

        }
      }

      a {
        position: relative;
        height: 50px;
        width: 100%;
        padding: 0;
        display: block;
        line-height: 50px;
        text-align: left;
        padding-left: 15px;

        &:after {
          background-position: 27px -144px;
          background-size: 56px;
          left: initial;
          bottom: initial;
        }
      }
    }
  }

  #block-pfcd-blocks-research {
    .research-wrapper {
      padding: 15px;
    }
  }

  #block-views-latest-news-block-1 {
    padding: 15px;
    height: initial;
  }

  #block-pfcd-blocks-sidebar-media {
    padding: 15px;
  }

  #block-views-exp-states-news-page-1 {
    width: 100%;

    #edit-tid-wrapper {
      text-align: center;

      select {
        @include button;
        text-transform: none;
        padding: 15px 20px;
        width: 260px;
        display: block;
        position: absolute;
        text-align: center;
        bottom: 52px;
        outline: none;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin-top: 105px !important;
        position: relative;
      }
    }

    .views-submit-button {
      margin-top: -150px;
    }
  }

  #block-block-2 {
    h3 {
      font-size: 30px;
    }
  }
}
