#footer {
  background-color: $footer-red;
  height: 96px;
  width: $full-width;

  &:after {
    content: " ";
    display: block;
    clear: both;
  }
  #footer-left {
    width: 33%;
    height: 100%;
    float: left;
    position: relative;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 30px;
      margin: auto;
    }
  }

  #footer-right {
    width: 66%;
    float: left;

    .block {
      width: 50%;
      float: left;

      p {
        color: $white;
        font-family: "source-sans-pro";
        font-weight: 600;
        font-style: italic;
        font-size: 14px;
        text-align: center;
        margin-top: 40px;
      }
    }

    #block-menu-menu-social-links {
      ul.menu {
        margin: 34px 0 0 0;
        padding: 0;
        float: right;

        li {
          list-style: none;
          float: left;
          padding: 0;
          margin: 0;

          a {
            display: block;
            background-image: url("../img/social-sprite.png");
            background-repeat: no-repeat;
            height: 31px;
            padding-left: 41px;
            line-height: 31px;
            width: 31px;
            overflow: hidden;
          }

          &.facebook a {
            background-position: 0 0*$social-link-sprites;
          }

          &.twitter a {
            background-position: 0 -3*$social-link-sprites;
          }

          &.youtube a {
            background-position: 0 -4*$social-link-sprites;
          }

          &.linkedin a {
            background-position: 0 -1*$social-link-sprites;
          }

          &.rss-feed a {
            background-position: 0 -2*$social-link-sprites;
          }
        }
      }
    }
  }
}

// even tho this is not in footer container, I'm putting it here because its always on the bottom of the page, just above footer.

.region-below {
  background-color: $footer-gray;
  width: $full-width;
  overflow: hidden;
  @include clearfix;

  .signup {

    width: 1020px;
    margin: auto;
    padding: 28px 10px;

    #webform-component-newsletter-text {
      width: 48%;
      float: left;
      margin: 0;
      padding: 0;

      p {
        margin: 0;
        padding: 0;
        font-weight: bold;
        height: 54px;
        line-height: 54px;
      }
    }

    #webform-component-email-address,
    .webform-component-email, .webform-component-cc_email {
      float: left;
    }

    input[type="submit"] {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: initial;
      padding: 0 45px;
    }

    input[type="text"],
    input[type="email"] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-left: 30px;
      width: 298px;

    }

    p {
      color: $font-black;
    }
  }
}


// Media queries

@media all and (max-width: 1023px) {
  $page-width: 100%;
  .region-below {
    .signup {
      width: $page-width;
      text-align: center;
      padding: 20px 0;

      input[type="submit"] {
        border-radius: 8px;
        margin-top: 25px;
      }

      #webform-component-newsletter-text,
      .webform-component-email, .webform-component-cc_email,
      #edit-actions--2,
      #edit-actions,
      {
        width: 100%;
        text-align: center;

        input {
          border-radius: 8px;
        }
      }

      #webform-component-email-address, .webform-component-cc_email {
        float: none;
        display: inline-block;
      }
      
      #edit-actions--2 {
        display: inline-block;
        margin-left: -5px;
      }
    }
  }

  #footer {
    height: 200px;
    #footer-left {
      width: 100%;
      height: initial;
      img {
        position: relative;
        margin-top: 25px;
        left: 0;
      }
    }
    #footer-right {
      width: 100%;
      height: initial;

      .block {
        width: 100%;

        p {
          margin: 20px 0;
        }
      }

      #block-menu-menu-social-links ul.menu {
        width: 205px;
        margin: 10px auto;
        height: 30px;
        float: none;
      }
    }
  }
}

@media all and (max-width: 359px) {
  .region-below {


    .signup {
      input[type="text"],
      input[type="email"] {
        width: 90%;
        box-sizing: border-box;
      }

      #webform-component-newsletter-text {
        p {
          height: initial;
          line-height: initial;
          margin-bottom: 15px;
        }
      }
    }
  }
}