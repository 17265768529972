header {
  position: fixed;
  z-index: 5;
  width: $full-width;
  height: 114px;
  background: rgba(0,0,0,0.5);
  .main-wrap {
    width: $full-width;
    margin: 0 auto;
    height: 0;

   #header-left,
   #header-right {
     display: inline-block;
     width: 50%;
     position: relative;
   }

    #header-left {
      a {
        display: block;
        width: 400px;
        img {
          margin: 22px;
        }
      }
    }

    #header-right {
      float: right;
      .region-header {

        #block-search-form {
          @include trans(0.2s);
          float: right;
          margin: 0 165px 0 0;
          opacity: 1;
          position: relative;
          input[type="submit"] {
            background-image: url("../img/menu-sprite.png");
            background-repeat: no-repeat;
            background-color: transparent;
            background-position: 0 -35px;
            height: 33px;
            border-radius: 0;
            width: 33px;
            overflow: hidden;
            padding: 0 0 0 33px;
            box-sizing: border-box;
            -webkit-transition: margin 0.3s linear;
            transition: margin 0.3s linear;
            text-indent: -1000em;

            &:hover {
              background-position: 0 -174px;
            }
          }

          input[type="text"] {
            box-sizing: content-box;
            -moz-box-sizing: content-box;
            -webkit-appearance: none;
            border: none;
            background-color: transparent;
            color: $white;
            box-shadow: none;
            text-align: right;
            font-size: 16px;
            font-family: "fira-sans";
            font-weight: 600;
            letter-spacing: initial;
            width: 400px;
            right: 35px;
            position: absolute;
          }

          // placeholder text color

          ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: $white;
          }
          :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: $white;
            opacity:  1;
          }
          ::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: $white;
            opacity:  1;
          }
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $white;
          }

          .form-type-textfield,
          .form-actions {
            display: inline-block;
            padding: 0;
            margin: 0;
            float: left;
          }

          .form-type-textfield {
            margin-top: 25px;
          }

          .form-actions {
            margin-top: 39px;
          }
        }
      }

      p.menu-title {
        width: 61px;
        top: 26px;
        color: white;
        font-size: 16px;
        font-family: "fira-sans";
        font-weight: 600;
        position: absolute;
        right: 71px;
      }

      .nav-toggle {
        background-image: url("../img/menu-sprite.png");
        background-repeat: no-repeat;
        background-color: transparent;
        height: 33px;
        border-radius: 0;
        width: 33px;
        overflow: hidden;
        padding: 0 0 0 33px;
        box-sizing: border-box;
        top: 40px;
        cursor: pointer;
        right: 41px;
        margin-right: 0;
        position: absolute;

        &:hover {
          background-position: 0px -139px;
        }
      }

      .menu-wrapper {
        position: absolute;
        top: 0;
        right: -495px;
        width: 495px;
        height: 1654px;
        background: $red;
        margin: 0;
        @include trans(0.3s);

        display: block;

        &.open {
          right: 0;
        }

        .menu-close {
          background-image: url("../img/menu-sprite.png");
          background-repeat: no-repeat;
          background-position: 90% -74px;
          background-position: calc(100% - 40px) -74px;
          height: 28px;
          width: 100%;
          margin: -40px 0px 80px 80px;

          cursor: pointer;
          -webkit-transition: margin 0.3s linear;
          transition: margin 0.3s linear;

          &.show {
            margin: 40px 0 0 0;
          }

          &:hover {
            background-position: calc(100% - 40px) -212px;
          }
        }

        ul#main-menu {
          width: 100%;

          li {
            width: 100%;
            display: block;
            line-height: 56px;
            margin-left: 65px;

            a {
              text-transform: uppercase;
              font-size: $f22;
              color: $white;
              height: 100%;
              display: block;

              &:hover {
                &:after {
                  content: "";
                  display: inline-block;
                  background-image: url("../img/menu-sprite.png");
                  background-position: 18px -104px;
                  background-repeat: no-repeat;
                  width: 100px;
                  height: 25px;
                }
              }
            }
          }
        }

        ul.secondary_menu {
          margin: 65px 0 0 0;
          padding: 0 0 0 65px;
          float: left;
          height: 32px;
          width: 275px;

          li {
            list-style: none;
            float: left;
            padding: 0;
            margin: 0;

            a {
              display: block;
              background-image: url("../img/social-sprite.png");
              background-repeat: no-repeat;
              height: 31px;
              padding-left: 41px;
              line-height: 31px;
              width: 31px;
              overflow: hidden;
            }

            &.facebook a {
              background-position: 0 0*$social-link-sprites;

              &:hover {
                background-position: 0 -168px;
              }
            }

            &.twitter a {
              background-position: 0 -3*$social-link-sprites;

              &:hover {
                background-position: 0 (-168 + (-3*$social-link-sprites));
              }
            }

            &.youtube a {
              background-position: 0 -4*$social-link-sprites;

              &:hover {
                background-position: 0 (-168 + (-4*$social-link-sprites));
              }
            }

            &.linkedin a {
              background-position: 0 -1*$social-link-sprites;

              &:hover {
                background-position: 0 (-168 + (-1*$social-link-sprites));
              }
            }

            &.rss-feed a {
              background-position: 0 -2*$social-link-sprites;

              &:hover {
                background-position: 0 (-168 + (-2*$social-link-sprites));
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 1023px) {
  header {
    .main-wrap {
      #header-right {
        .region-header #block-search-form {
          input[type="text"] {
            width: 200px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 767px) {
  header {
    height: 131px;
    @include trans(0.2s);
    .main-wrap {
      #header-left {
        @include trans(0.2s);
        width: 100%;

        &:after {
          content: "";
        }

        a {
          width: 250px;
          margin: 10px auto 0;

          img {
            width: 100%;
            margin: 0;
          }
        }
      }

      #header-right {
        @include trans(0.2s);
        width: 100%;
        margin-top: -20px;

        .region-header {
          #block-search-form {
            input[type="text"] {
              width: 350px;
            }
          }
        }

        .menu-wrapper {
          @include trans(0.2s);

          &.open {
            width: 100%;
            padding-top: 45px;
            margin-top: -45px;
            //height: 400px;
          }

          ul#main-menu {
            li {
              width: 300px;
              margin-left: 10px;
              line-height: 40px;

              a:after {
                position: absolute;
              }
            }
          }

          ul.secondary_menu {
            margin: 10px 0 0 0;
            padding: 0 0 0 10px;
          }
        }
      }
    }

    &.scrolled {
      height: 65px;
      .main-wrap {
        #header-left {
          width: 50%;
          position: relative;
          a {
            width: 100%;
            margin: 17px 15px;

            img {
              width: calc(100% - 15px);
            }
          }
        }

        #header-right {
          width: 50%;

          #block-search-form {
            opacity: 0;
            visibility: hidden;

            input[type="text"] {
              display: none;
            }
          }

          .menu-wrapper.open {
            width: 200%;
          }
        }
      }
    }
  }
}

@media all and (max-width: 359px) {
  header {
    .main-wrap {
      #header-right {
        .menu-wrapper {
          &.open {
            //height: 400px;
          }
        }
      }
    }
  }
}
