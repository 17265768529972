body {
  &.html.not-front.no-sidebars.page-node.page-node-.node-type-webform{
    #container{
      div:nth-child(3){
        &.column-wrapper{
          padding-top: 90px;
          background: url("../img/scrabble.png");
          margin-top: 90px;
          background-repeat: repeat-x;
          .region.region-columns {
            height: 100%;
            background-color: #F0F0F0;
            .block-views{
              max-width: 1000px;
              margin: 0 auto;
              padding: 0 15px;
              h3{
                text-align: center;
                text-transform: uppercase;
                &:after{
                  content: ' ';
                  width: 700px;
                  background-color: #cfcece;
                  height: 2px;
                }
              }
              div.block-content{
                position: relative;
                padding: 60px 0;
                &:before {
                  content: ' ';
                  width: 70%;
                  top: 20px;
                  height: 2px;
                  background-color: #cfcece;
                  left: 15%;
                  position: absolute;
                }
                .view-content{
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: flex-start;
                  .views-row {
                    width: calc(25% - (20px*0.75));
                    margin-right: 20px;
                    margin-bottom: 30px;
                    font-family: "ff-tisa-web-pro";
                    &:nth-child(4n+4){
                      margin-right: 0;

                    }
                  }
                }
              }
            }
            .block-block {
              max-width: 700px;
              padding: 0 20px;
              margin: 0 auto;
              span.letter-footnotes {
                font-size: 9px;
              }
            }
          }
        }
      }
    }
  }
  &.page-resources {

    #container #page .main-wrap {
      padding: 0;
      .featured-wrapper {
        margin-bottom: 20px;
      }

      h1#page-title {
        margin: 0;
        padding: 0;
      }

      .region-featured {
        margin-top: 20px;
      }
    }

    .view-resources {
      width: 100%;

      .view-content {
        .views-row {
          width: 320px;
          height: 471px;
          margin: 0 10px 20px;
          float: left;
          padding-bottom: 37px;
          @include box-shadow;

          img {
            width: 100%;
            height: auto;
          }

          .views-field-field-resources-image {
            height: 220px;
            overflow: hidden;
          }

          .views-field-title {
            float: left;
            padding: 27px 23px 18px;

            &:hover {
              a {
                color: $red;
              }
            }

            a {
              font-size: 22px;
              color: $black;
              //padding: 27px 23px 18px;
              float: left;
              height: 65px;
              display: block;
              overflow: hidden;
            }
          }

          .views-field-body {
            float: left;
            font-size: 16px;
            color: $font-gray;
            padding: 0 23px;
            margin: 0;
            height: 110px;
            overflow: hidden;
            p {
              font-family: inherit;
              font-weight: inherit;
              font-size: inherit;
              color: inherit;
            }

            span.field-content {
              word-wrap: break-word;
              display: table;
              table-layout: fixed;
              width: 100%;
            }

          }

          &.views-row-2 {
            margin-right: 11px;
          }
        }
      }
    }
  }

  &.page-blog,
  &.page-events.one-sidebar,
  &.page-latest-news.one-sidebar {
    .region-content {
      width: 660px;
      float: left;
    }
    .view-blog,
    .view-events,
    .view-latest-news {

      //@include horizontal_background;

      .view-header {
        height: 352px;
        margin-bottom: 140px;
      }

      .views-row {
        .views-field-title {
          a {
            font-size: 26px;
            text-transform: uppercase;
            position: relative;

            &:hover {
              color: $font-red;

              &:before {
                content: "";
                background-image: url("../img/arrows-sprite.png");
                background-repeat: no-repeat;
                background-position: 0 -26px;
                position: absolute;
                display: block;
                height: 16px;
                width: 10px;
                top: 11px;
                left: -40px;
              }
            }
          }
        }

        .views-field-body {
          border-bottom: 1px solid $gray-5;
          padding-bottom: 20px;
          margin: 10px 0;
          p, .field-content {
            font-family: "ff-tisa-web-pro";
            font-size: 18px;
            color: $font-black;
            margin: 0;
          }
        }

        .views-field-name,
        .views-field-term-node-tid {
          padding-right: 20px;
          display: inline-block;
          a,
          span {
            color: $font-red;
            font-family: "source-sans-pro";
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            &.field-content:after {
              content: "•";
              position: relative;
              left: 12px;
            }
          }
        }

        .views-field-created {
          position: relative;
          display: inline-block;
          color: $font-red;
          font-family: "source-sans-pro";
          font-size: 14px;
          text-transform: uppercase;
          font-weight: bold;
          padding-bottom: 60px;

        }
      }
    }

    .region-sidebar-first {
      width: 340px;
      padding-left: 20px;
      float: left;
      position: relative;

      #views-exposed-form-events-page,
      #block-views-exp-latest-news-page {
        .views-exposed-widget {
          float: none;
          padding-left: 30px;

          .views-widget {
            .form-item {
              padding: 0;
              margin: 0;
            }
          }

          .chosen-container {
            position: relative;
            width: 260px !important;
            bottom: 0;
          }

          > a {
            @include button;
            background-color: $font-black;
            text-align: center;
            height: 54px;
            width: 260px;
            display: block;

            &:hover {
              background-color: $font-black-hover;
            }
          }
        }

        #edit-tid-wrapper {
          position: relative;
          top: 82px;

          a {
            text-align: left;
            padding-left: 21px;
            @include user_select;
          }
        }

        .views-submit-button {
          position: absolute;
          top: 0px;
        }
      }

      #edit-submit-events,
      #edit-submit-latest-news {
        display: none;
      }
    }
  }

  &.page-node-.no-sidebars {
    #container {
      #page {
        position: initial;
        .main-wrap {
          max-width: $page-width;
          padding: 37px 20px 0;

          #main {
            max-width: 700px;
            margin: auto;
          }

          .featured-wrapper {
            margin-bottom: 65px;
          }


        }
      }
    }
  }

  &.page-node-.one-sidebar {
    #container {
      #page {
        .main-wrap {
          width: $page-width;
          padding: 0 10px;

          #main {
            display: table;
          }

          .region-content {
            width: 595px;
            //float: left;
            padding-right: 20px;

            float: none;
            vertical-align: top;
            display: table-cell;

            &:after {
              content: "";
              height: 1px;
              display: block;
              width: 100%;
              clear: both;
            }
          }

          .region-sidebar-first {
            width: 405px;
            //float: left;
            position: relative;
            background-color: $gray-1;
            padding: 65px 10px 0 85px;
            background-image: url("../img/container-background-vert.png");
            background-repeat: repeat-y;
            background-position: 0 0;

            float: none;
            vertical-align: top;
            display: table-cell;

            &:after {
              content: "";
              background-color: $gray-1;
              position: absolute;
              height: 100%;
              width: 1000px;
              top: 0;
              right: -1000px;
            }

            #block-pfcd-blocks-sidebar-media {
              width: 100%;
              margin-left: 0;
            }

            #block-pfcd-blocks-video {
              width: 310px;
              height: initial;
              @include box-shadow;
              iframe {
                width: 100%;
                height: 175px;
              }
            }

            #block-views-resources-block-1 {
              float: left;
              background-color: $gray-1;
              padding: 27px;
              margin: 32px 0;
              @include box-shadow;

              .views-row {
                padding-bottom: 20px;
                a {
                  display: block;
                  width: 100%;
                  position: relative;
                  color: $font-gray-2;

                }
                &:hover {
                  a {
                    color: $font-red;
                    &:after {
                      content: "";
                      background-image: url("../img/arrows-sprite.png");
                      position: absolute;
                      background-repeat: no-repeat;
                      background-position: 0 -26px;
                      width: 10px;
                      height: 17px;
                      top: 3px;
                      right: -17px;
                    }
                  }
                }
              }
            }

            #block-views-issues-block-1 {
              width: 100%;
              height: initial;
              margin-left: 0;
              .view-issues {
                .views-row {
                  @include box-shadow;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  &.page-node-,
  &.page-taxonomy- {
    #container {
      #page {
        .main-wrap {
          .region-content {
            @include content_text;
          }
        }
      }
    }
  }

  &.page-latest-news {
    .bgd-image-wrapper {
      background-image: url("../img/latest-news-background.png");
      background-repeat: repeat-x;
      background-position: 0px 0px;
      background-color: $gray-1;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
    }
  }

  &.page-blog,
  &.page-events {
    .bgd-image-wrapper {
      margin-top: 77px;
    }
  }

  &.page-search {
    .search-advanced {
      display: none;
    }

    .region-content h2 {
      border-bottom: 1px solid $gray-5;
      padding-bottom: 40px;

      display: none;
    }

    #search-form {
      .form-item-keys {
        label {
          color: $font-red;
          font-family: "source-sans-pro";
          text-transform: uppercase;
          font-size: 14px;
          font-weight: bold;
          width: 100%;
          display: block;
          padding-bottom: 15px;
          padding-top: 51px;
          text-align: left;
        }

        input#edit-keys {
          border-radius: 0;
          box-shadow: none;
          border: 1px solid $gray-5;
          width: 660px;
          margin-right: 55px;
        }
      }
    }

    ol.search-results {
      background-image: url("../img/container-background-hor.png");
      background-repeat: repeat-x;
      background-position: 0 0;
      padding-top: 60px;
      margin-top: 60px;
      background-color: $gray-1;
      position: relative;
      width: 660px;
      margin-bottom: 0;

      &:before,
      &:after {
        background-image: url("../img/container-background-hor.png");
        background-repeat: repeat-x;
        background-position: 0 0;
        content: "";
        position: absolute;
        top: 0;
        width: 1000px;
        height: 100%;
        background-color: $gray-1;
      }

      &:after {
        right: -1000px;
      }

      &:before {
        left: -1000px;
      }

      h3.title {
        a {
          font-size: 26px;
          text-transform: uppercase;
          position: relative;

          &:hover {
            color: $font-red;

            &:before {
              content: "";
              background-image: url("../img/arrows-sprite.png");
              background-repeat: no-repeat;
              background-position: 0 -26px;
              position: absolute;
              display: block;
              height: 16px;
              width: 10px;
              top: 11px;
              left: -40px;
            }
          }
        }
      }

      .search-snippet-info {
        padding-left: 0;
        .search-snippet {
          font-family: "ff-tisa-web-pro";
          font-size: 18px;
          color: $font-black;
          border-bottom: 1px solid #cfcece;
          padding-bottom: 40px;
          margin: 10px 0;

          strong {
            background-color: #FFFF00;
          }
        }

        .search-info {
          color: $font-red;
          font-family: "source-sans-pro";
          text-transform: uppercase;
          font-size: 14px;
          font-weight: bold;
        }
      }

      li {
        margin-bottom: 55px;

        &:last-child {
          margin-bottom: 0;

          .search-info {
            margin: 0;
            padding: 0;
            padding-bottom: 30px;
          }
        }
      }
    }

    .item-list ul.pager {
      height: 63px;
    }

    ul:not(.pager) {
      margin-bottom: 65px;
      li {
        font-family: "ff-tisa-web-pro";
        font-size: 18px;
        color: $font-black;
      }
    }

    .region-sidebar-first {
      width: 340px;
      position: absolute;
      top: 309px;
      right: 0;
      display: inline-block;
      text-align: center;

      .chosen-container {
        max-width: 260px;
        position: relative;
        display: inline-block;
        margin-top: 65px;

        a {
          width: 260px;
          text-align: left;
          padding-left: 25px;
        }

        .chosen-results {
          li {
            text-align: left;
          }
        }
      }
    }
  }

  &.page-issues {
    .view-issues {
      margin-top: 52px;
      .views-row {
        position: relative;
        @include box-shadow;
        margin-bottom: 53px;

        &:after {
          content: "";
          clear: both;
          display: block;
          width: 100%;
          opacity: 0;
        }

        .views-field-name {
          background-image: url("../img/issues-sprite.png");
          background-repeat: no-repeat;
          position: absolute;
          height: 74px;
          left: 16px;
          top: 14px;

          a {
            display: block;
            height: 74px;
            line-height: 74px;
            padding-left: 83px;
            font-size: 28px;
            font-family: "fira-sans";
            text-transform: uppercase;
          }
        }

        .views-field-description {
          width: 490px;
          float: left;
          padding: 65px 65px 0 100px;

          p {
            font-size: 16px;
            color: $font-gray;
            font-family: "source-sans-pro";

            a.views-more-link {
              @include button;
              position: absolute;
              bottom: 130px; // 80 from additional resources and 50 from bottom
              width: 320px;
            }

            img {
              width: 100%;
              height: auto;
            }
          }
        }

        .views-field-field-issues-image {
          width: 510px;
          float: left;
        }

        .views-field-field-additional-resources {
          float: left;
          width: 100%;
          height: 80px;
          background-color: $gray-1;

          span.views-label {
            display: block;
            width: 320px;
            height: 80px;
            background-color: $gray-4;
            font-size: 18px;
            font-family: "fira-sans";
            text-transform: uppercase;
            line-height: 80px;
            text-align: center;
            float: left;
          }

          ul {
            width: 680px;
            float: left;
            height: 80px;
            line-height: 80px;
            background-color: $gray-1;
            overflow: hidden;


            li {
              height: 100%;
              list-style-type: none;
              width: 226px;
              padding: 0 10px 0 60px;
              margin: 0;
              position: relative;
              float: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              a {
                font-size: 18px;
                color: $font-red;
                text-transform: capitalize;
                position: relative;

                &:hover {
                  &:before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 30px;
                    height: 30px;
                    top: 0;
                    bottom: 0;
                    left: -17px;
                    margin: auto;
                    background-image: url("../img/arrows-sprite.png");
                    background-repeat: no-repeat;
                    background-position: 0 -19px;
                  }
                }
              }
            }
          }
        }
        .alzheimers-disease .views-field-name {
          background-image: url("../img/alzheimers_v2.png");
        }
        &.cancer .views-field-name {
          background-position: 0 0;
        }

        &.diabetes .views-field-name {
          background-position: 0 -92px;
        }

        &.heart-disease .views-field-name {
          background-position: 0 -178px;
        }

        &.obesity .views-field-name {
          background-position: 0 -266px;
        }

        &.asthma .views-field-name {
          background-image: url("../img/asthma_v2.png");
        }

        &.arthritis .views-field-name {
          background-image: url("../img/arthritis_v2.png");
        }

        &.mental-health .views-field-name {
          background-image: url("../img/mental-health_v2.png");
        }

        &.chronic-migraines .views-field-name {
          background-image: url("../img/chronicmigraines_v2.png");
          height: 84px;
        }
      }
    }
  }

  &.page-taxonomy {
    .vocabulary-states {
      .state-heading {
        width: 100%;
        float: left;
        margin-top: 35px;

        .state-heading-block {
          position: relative;
          width: 660px;
          background-color: $gray-1;
          float: left;
          height: 465px;
          padding: 15px 51px;
          @include box-shadow;
          @media (max-width: 1023px) {
            height: initial;
          }

          .field-type-text {
            .field-item {
              text-transform: uppercase;
              color: $font-black;
              font-size: 26px;
              font-family: "fira-sans";
            }
          }

          .taxonomy-term-description {
             height: auto;
            // overflow: hidden;
            p {
              font-family: "ff-tisa-web-pro";
              font-size: 18px;
              font-weight: bold;
              margin: 0;
              padding: 0;
            }
          }

          .download-btn {
            a {
              @include button;
              // position: absolute;
              // bottom: 45px;
              // width:  320px;
              font-size: 14px;
              margin-bottom: 15px;
              background: #ef0325 url(../img/icon-download.png) no-repeat 7px center;
              width: 250px;
              float: left;
              margin-right: 10px;
            }
          }
          .download-btn + .download-btn {
            a {
              margin-right: 0;
            }
          }
          .download-btn + .download-btn + .download-btn {
            a {
              margin-right: 10px;
            }
          }
        }

        .state-video-block {
          // position: relative;
          // width: 320px;
          margin-left: 20px;
          height: 465px;
          // background-color: $panel-background;
          // float: left;
          // height: 405px;
          // @include box-shadow;
          padding: 28px 0px 70px;

          // .state-video-heading {
          //   font-size: 22px;
          //   font-family: "fira-sans";
          //   font-weight: 500;
          //   color: $white;
          //   margin-bottom: 25px;
          // }
          //
          // .state-video {
          //   a {
          //     @include button;
          //     position: absolute;
          //     bottom: 45px;
          //     width: 260px;
          //   }
          // }
          //
          // iframe {
          //   width: 260px;
          //   height: 150px;
          // }
        }
      }

      .co-chair-list {
        width: 100%;
        float: left;
        margin-top: 54px;
        @include box-shadow;
        padding: 32px 65px;

        .title {
          margin-bottom: 25px;
          h2 {
            font-size: 22px;
            display: inline;
            padding-right: 15px;
            text-transform: initial;
          }
          p {
            display: inline;
            font-size: 14px;
            color: $font-gray;
          }
        }

        .view-co-chair-view {

          .views-row {
            float: left;
            width: 105px;
            margin-right: 22px;

            .views-field-field-image {
              height: 146px;
              overflow: hidden;

              img {
                width: 100%;
                height: auto;
              }
            }

            &:hover {
              .views-field-field-image .field-content a {
                position: relative;
                display: block;
                height: 100%;
                width: 100%;
                &:before {
                  content: "VIEW BIO";
                  color: white;
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0,0,0,0.8);
                  line-height: 172px;
                  text-align: center;
                  font-size: 16px;
                  font-family: "source-sans-pro";
                  font-weight: bold;
                }

                &:after {
                  content: "";
                  background-image: url("../img/homepage-sprite.png");
                  background-repeat: no-repeat;
                  background-position: 24px bottom;
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 46px;
                  top: 23px;
                  left: 0;
                  right: 0;
                  margin: auto;
                  background-size: 55px;
                }
              }

              .views-field-title {
                a {
                  color: $font-red;

                }
              }
            }

            .views-field-title {
              a {
                text-transform: uppercase;
                width: 105px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                margin-top: 10px;
              }
            }
          }
        }
      }

      .partners-links-wrapper {
        width: 100%;
        float: left;
        margin-top: 54px;
        @include box-shadow;
        padding: 32px 65px;

        .title {
          margin-bottom: 25px;
          h2 {
            font-size: 22px;
            display: inline;
            padding-right: 15px;
            text-transform: initial;
          }
          p {
            display: inline;
            font-size: 14px;
            color: $font-gray;
          }
        }

        .field-item {
          padding: 10px 0;
          width: 50%;
          float: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          height: 39px;

          &:hover {
            overflow: initial;
            text-overflow: initial;
            white-space: initial;

            a {
              position: absolute;
              z-index: 2;
              background-color: $white;
              padding-right: 10px;
            }
          }

          a {
            font-family: "source-sans-pro";
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            position: relative;
            z-index: 1;


            &:hover {
              color: $font-red;
            }
          }
        }
      }

      .view-states-news {
        width: 660px;
        float: left;
        background-image: url("../img/container-background-hor.png");
        background-repeat: repeat-x;
        background-position: 0 0;
        padding-top: 120px;
        margin-top: 60px;
        background-color: $gray-1;
        position: relative;

        &:before,
        &:after {
          background-image: url("../img/container-background-hor.png");
          background-repeat: repeat-x;
          background-position: 0 0;
          content: "";
          position: absolute;
          top: 0;
          width: 1000px;
          height: 100%;
          background-color: $gray-1;
        }

        &:after {
          right: -1000px;
        }

        &:before {
          left: -1000px;
        }

        .view-header {
          height: 352px;
          margin-bottom: 140px;
        }

        .views-row {
          .views-field-title {
            a {
              font-size: 26px;
              text-transform: uppercase;
              position: relative;

              &:hover {
                color: $font-red;

                &:before {
                  content: "";
                  background-image: url("../img/arrows-sprite.png");
                  background-repeat: no-repeat;
                  background-position: 0 -26px;
                  position: absolute;
                  display: block;
                  height: 16px;
                  width: 10px;
                  top: 11px;
                  left: -40px;
                }
              }
            }
          }

          .views-field-body {
            border-bottom: 1px solid $gray-5;
            padding-bottom: 40px;
            margin: 10px 0;
            p, .field-content {
              font-family: "ff-tisa-web-pro";
              font-size: 18px;
              color: $font-black;
              margin: 0;
            }
          }

          .views-field-term-node-tid {
            padding-top: 17px;
            padding-bottom: 60px;
            display: inline-block;
            a,
            span {
              color: $font-red;
              font-family: "source-sans-pro";
              text-transform: uppercase;
              font-size: 14px;
              font-weight: bold;
            }
          }

          .views-field-created {
            position: relative;
            display: inline-block;
            color: $font-red;
            font-family: "source-sans-pro";
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
            padding-left: 20px;
          }
        }

        .view-empty {
          height: 400px;
          text-decoration: none;
          font-family: "fira-sans";
          font-weight: 500;
          color: $font-black;
          font-size: 26px;
          text-transform: uppercase;
        }
      }
    }

    .vocabulary-issues {
      .state-heading {
        width: 660px;
        float: left;
        margin-top: 35px;

        .state-heading-block {
          position: relative;
          width: 660px;
          float: left;
          height: 405px;
          @include box-shadow;
          padding-left: 105px;
          padding-right: 65px;

          .field-type-text {
            background-image: url("../img/issues-sprite.png");
            background-repeat: no-repeat;
            height: 74px;
            margin-left: -80px;
            margin-top: 15px;

            &.cancer {
              background-position: 0 0;
            }

            &.diabetes {
              background-position: 0 -92px;
            }

            &.heart-disease {
              background-position: 0 -180px;
            }

            &.obesity {
              background-position: 0 -267px;
            }

            &.mental-health {
              background-image: url("../img/mental-health_v2.png");
            }

            &.alzheimers-disease {
              background-image: url("../img/alzheimers_v2.png");
            }

            &.asthma {
              background-image: url("../img/asthma_v2.png");
            }

            &.arthritis {
              background-image: url("../img/arthritis_v2.png");
            }

            &.chronic-migraines {
              background-image: url("../img/chronicmigraines_v2.png");
              height: 83px;
            }

            .field-item {
              text-transform: uppercase;
              color: $font-black;
              font-size: 28px;
              font-family: "fira-sans";
              height: 74px;
              line-height: 74px;
              padding-left: 80px;
            }
          }

          .taxonomy-term-description {
            height: 173px;
            overflow: hidden;
            margin-top: 10px;
            p {
              font-family: "source-sans-pro";
              font-size: 16px;
              margin: 0;
              padding: 0;
              color: $font-gray;
            }
          }

          .download-btn {
            a {
              @include button;
              //position: absolute;
              bottom: 45px;
              width:  240px;
            }
          }
        }
      }

      .state-heading .state-video-block,
      .partners-links-wrapper {
        display: none;
      }

      .related-news-list {
        position: relative;
        width: 320px;
        margin-left: 20px;
        background-color: $panel-background;
        float: left;
        height: 405px;
        @include box-shadow;
        padding: 28px;
        margin-top: 35px;

        .title {
          h2 {
            color: $white;
            font-size: 22px;
            text-transform: none;
          }
        }

        .view-issues-related-news {
          .views-row {
            margin-bottom: 25px;
            a {
              color: $font-gray;
            }

            .views-field-term-node-tid {
              display: none;
            }
          }
        }
      }

      .secondary-heading-block {
        float: left;
        width: $page-width;
        background-image: url("../img/container-background-hor.png");
        background-repeat: repeat-x;
        background-position: 0 0;
        padding-top: 120px;
        margin-top: 60px;
        background-color: $gray-1;
        position: relative;

        &:before,
        &:after {
          background-image: url("../img/container-background-hor.png");
          background-repeat: repeat-x;
          background-position: 0 0;
          content: "";
          position: absolute;
          top: 0;
          width: 1000px;
          height: 100%;
          background-color: $gray-1;
        }

        &:after {
          right: -1000px;
        }

        &:before {
          left: -1000px;
        }

        .field-name-field-secundary-headline {
          width: 700px;
          margin: auto;
          font-family: "fira-sans";
          font-size: 26px;
          text-transform: uppercase;
          border-bottom: 1px solid $gray-5;
          padding-bottom: 25px;
        }

        .field-name-field-secondary-description {
          width: 700px;
          margin: auto;
          @include content_text;

          p {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    .field-name-field-quick-facts {
      @extend .co-chair-list;

      .field-items:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
      }

      .field-item {
        max-width: 215px;
        height: 150px;
        width: 100%;
        float: left;
        background-image: url(../../img/svg/PFCD_ScaleV2_icon.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        position:relative;
        display: table;
        margin-bottom: 35px;

        &:hover {
          background-image: url(../../img/svg/PFCD_FactSheet_icons_scale.hover.svg);

          p.result {
            display: table-cell;
          }

          &:after {
            color: #ed1c2a;
          }
        }

        &:after {
          content: "are overweight or obese";
          position: absolute;
          bottom: -50px;
          text-transform: uppercase;
          display: block;
          width: 135px;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
        }

        p.result {
          vertical-align: middle;
          text-align: center;
          position: relative;
          font-size: 22px;
          color: #fff;
          bottom: -8px;
          display: none;
        }
      }

      .field-item:nth-child(2) {
        background-image: url(../../img/svg/PFCD_FactSheet_icons_apple.static.svg);

        &:hover {
          background-image: url(../../img/svg/PFCD_FactSheet_icons_apple.hover.svg);
        }
        &:after {
          content: "eat less than 1 fruit a day";
        }
      }

      .field-item:nth-child(3) {
        background-image: url(../../img/svg/PFCD_FactSheet_icons_vegetable.static.svg);

        &:hover {
          background-image: url(../../img/svg/PFCD_FactSheet_icons_vegetable.hover.svg);
        }
        &:after {
          content: "eat less than 1 vegetable a day";
        }

        p.result {
          bottom: 8px;
          right: 8px;
        }
      }

      .field-item:last-child {
        background-image: url(../../img/svg/PFCD_FactSheet_icons_smoking.static.svg);

        &:hover {
          background-image: url(../../img/svg/PFCD_FactSheet_icons_smoking.hover.svg);
        }
        &:after {
          content: "Smoke Cigarettes";
        }
        p.result {
          bottom: -18px;
          right: 6px;
        }
      }

    }

    #block-views-exp-latest-news-page {
      position: relative;
    }
  }

  &.page-pfcd-in-the-states {
    #container #page {
      .region-hero {
        height: 400px;
        position: relative;
      }

      .main-wrap .featured-wrapper {
        width: 1000px;
        margin: auto;
        margin-bottom: 0;
      }
    }

    #block-views-pfcd-in-the-states-block-1 .view-pfcd-in-the-states {
      padding: 30px 0 !important;
    }

    .view-pfcd-in-the-states {
      padding: 30px 0 30px;
      margin-bottom: 0;

      .views-row {
        width: 320px;
        margin: 0px 0px 20px 0;
        display: inline-block;
        background: #f0f0f0;
        float: left;
        margin-right: 20px;
        position: relative;
        overflow: hidden;
        @media (max-width: 1023px) {
          display: none; // hide state grid for devices < 1024px wide -- default will be dropdown nav instead
        }

        .statemap {
          margin-left: 0;
          @include trans(0.4s);

        }

        .quickfact {
          background: #ef0325;
          width: 50%;
          color: #fff;
          padding: 20px 25px;
          position: absolute;
          height: calc(100% - 55px);
          left: -50%;
          @include trans(0.4s);

          a {
            padding: 12px 18px;
            background: #8f191f;
            border-radius: 8px;
            font-size: 14px;
            margin-top: 18px;
            display: block;
            text-align: center;
            color: #fff;
            @include trans(0.2s);
            &:hover {
              background: #AA1F26;
            }
          }

          span.views-label {
            font-family: "fira-sans", sans-serif;
            text-transform: uppercase;
            font-size: 18px;
          }

          .field-content {
            font-family: "ff-tisa-web-pro";
            font-size: 16px;
            line-height: 22px;
          }

        }

        &:hover {
          .quickfact {
            left: 0;
          }

          .statemap {
            margin-left: 50%;
          }
        }

        .views-field-field-image {

          a {
            img {

            }
          }
        }

        .views-field-name {
          width: 100%;
          a {
            border-radius: 0;
            background-color: #dcdcdc;
            color: #000;
            text-transform: uppercase;
            font-size: 18px;
            padding: 15px 0px;
            display: block;
            text-align: center;
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
            width: 100%;
          }
        }
      }
     .views-row:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  &.node-type-co-chair {
    .region-content {
      .co-chair-detail {
        margin-top: 65px;
        padding-bottom: 35px;
        border-bottom: 1px solid $gray-5;

        .field-name-field-image {
          float: left;
          margin-right: 35px;
        }

        .co-chair-titles {

          h2 {
            margin: 0;
            padding: 0;
          }
          .field {
            font-size: 18px;
            font-family: "fira-sans";
            font-weight: 500;

            &.field-name-field-secondary-title {

            }

            &.field-name-field-third-title {
              color: $font-red;
              a {
                font-style: normal !important;
                text-decoration: none !important;
                &:hover {
                  text-decoration: underline !important;
                }
              }
            }
          }
        }

        @include clearfix;
      }
    }

    .region-sidebar-first {

    }
  }

  &.page-user {
    #container #page .main-wrap {
      width: 660px;

      .tabs {
        position: static;
      }

      .profile {
        font-family: "fira-sans";
        font-weight: 500;
        margin-top: 30px;
      }

      #user-login,
      #user-profile-form {
        .confirm-parent,
        .password-parent {
          width: initial;
          position: relative;
        }

        .password-strength {
          position: absolute;
          right: 0;
          top: 0;
          margin-top: 0;
        }

        #edit-pass-pass1 {
          margin-top: 15px;
        }

        .password-suggestions {
          width: 100%;
        }

        .chosen-drop {
          position: relative;
        }

        label {
          font-family: "source-sans-pro";
          font-weight: 800;
          text-transform: uppercase;
        }

        input[type="text"],
        input[type="password"] {
          width: 100%;
          box-sizing: border-box;
        }

        .description {
          font-family: "fira-sans";
          font-weight: 400;
          margin-top: 10px;
        }
      }
    }
    @include stickyfooter(214px, ".footer", ".page", "#container");
  }

  // 404 page
  &.page-node-19 {
    @include stickyfooter(96px, ".footer", ".page", "#container");

    #search-block-form--2 {
      input[type="text"]#edit-search-block-form--4 {
        width: 660px;
        margin-top: 20px;
        margin-right: 55px;
      }
    }
  }

  &.page-speakers {
    .view-speaker {
      margin-top: 65px;
      margin-bottom: 65px;
      float: left;

      .views-row {
        margin-bottom: 15px;
        width: 100%;
        float: left;

        .views-field-field-speaker-image {
          float: left;
          margin-right: 15px;
        }

        .views-field-title {

        }
      }
    }
  }

  &.page-blog {
    .item-list {
      .pager {
        background-color: transparent;
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }
}

body.node-type-webform {
  #container #page #main .node-webform .node-content {
    .webform-client-form {
      &[action="/open-letter-2016-presidential-candidates"] {
        input.form-text {
          width: 330px;
        }
        input#edit-submitted-organization, input#edit-submitted-title {
          width: 672px;
        }
        #edit-actions {
          position: relative;
          left: 210px;
        }
      }

      div .webform-component {

        &#webform-component-call-to-action hr {
          border-color: $font-gray;
          margin: 30px 0;
          width: 100%;
        }

        &#webform-component-call-to-action span, &#webform-component-agreement label {
          font-family: "source-sans-pro";
          font-size: 16px;
          color: $font-gray;
          font-weight: 600;

          &.headline {
            font-family: "fira-sans";
            font-size: 24px;
            color: $font-black;
            font-weight: bold;
          }

          &.red {
            color: $font-red;
          }
        }

        margin-left: 0;
      }
    }
  }
}

@media all and (max-width: 1023px) {
  $page-width: 660px;

  body {
    div.section-title {
      width: $page-width;
    }

    &.page-node-.one-sidebar #container #page .main-wrap {
      width: $page-width;

      #main {
        display: block;
      }

      .region-content {
        width: 100%;
        display: block;
        padding: 0;
      }

      .region-sidebar-first {
        width: 100%;
        display: block;
        background-image: url("../img/container-background-hor.png");
        background-repeat: repeat-x;
        float: left;
        padding: 85px 10px;

        &:after {
          background-image: url("../img/container-background-hor.png");
          background-repeat: repeat-x;
        }

        &:before {
          content: "";
          background-image: url("../img/container-background-hor.png");
          background-repeat: repeat-x;
          background-color: $gray-1;
          position: absolute;
          height: 100%;
          width: 1000px;
          top: 0;
          left: -1000px;
        }

        #block-pfcd-blocks-video {
          width: 100%;
          height: 375px;

          iframe {
            height: 375px;
            width: 100%;
          }
        }
      }
    }

    &.page-node-.no-sidebars.node-type-webform #container #page .main-wrap {
      width: $page-width;

      #main {
        display: block;
        width: 100%;
        margin: 0 15px;

        .region-content {
          display: block;
          padding: 0;

          .node-webform .node-content .webform-client-form {
            display: block;
            input.form-text, input.form-submit, #webform-component-agreement {
              max-width: 580px;
            }

            &[action="/speak-out"] {
              padding: 20px 40px;
            }

            &[action="/open-letter-2016-presidential-candidates"] {
              input.form-text, #webform-component-agreement {
                width: 100%;
              }

              #edit-actions {
                left: 0;
                margin-left: 0;
                width: 100%;

                input {
                  width: 100%;
                  padding: 0;
                }
              }
            }

            h4 span {
              display: block;
            }
          }
        }
      }
    }

    &.page-resources {
      .view-resources {
        .view-content {
          .views-row {
            width: 100%;
            margin: 0 0 20px;
            height: initial;

            .views-field-title a {
              height: initial;
            }

            .views-field-body {
              height: initial;
            }
          }
        }
      }
    }

    &.page-latest-news {
      .bgd-image-wrapper {
        display: none;
      }

      #container #page .main-wrap .featured-wrapper {
        margin-bottom: 0;
      }

      .region-content .view-latest-news {
        @include horizontal_background;
        padding-top: 90px;
      }
    }

    &.page-blog,
    &.page-events.one-sidebar,
    &.page-latest-news.one-sidebar {
      .region-sidebar-first {
        width: 100%;
        padding-left: 0;
        padding: 50px 0;
        clear: both;
        text-align: center;
        display: block;

        #block-views-exp-latest-news-page {
          @include horizontal_background;

          text-align: center;
          margin: auto;
          #edit-tid-wrapper {
            top: 0;
          }

          .views-submit-button {
            top: 0;
            a.active {
              position: relative;
              margin: auto;
              margin-bottom: 25px;
            }
          }

          .views-exposed-widgets {
            margin-bottom: 0;
          }

          .views-exposed-widget {
            .chosen-container {
              margin: auto;
            }
          }
        }
      }
    }

    &.page-blog,
    &.page-events.one-sidebar,
    &.page-latest-news.one-sidebar {
      .region-content {
        width: 100%;
      }

      .region-sidebar-first {
        #block-views-exp-latest-news-page {
          width: 100%;
          background-image: none;

          &:before,
          &:after {
            background-image: none;
          }
        }
      }
    }

    &.page-latest-news {
      .region-content {
        .view-latest-news {
          width: 100%;
        }
      }
    }

    &.page-issues {
      .view-issues {
        .views-row {
          .views-field-name {
            position: relative;
          }

          .views-field-description {
            width: 100%;
            padding: 25px;

            p {
              a.views-more-link {
                position: relative;
                bottom: 0;
                top: 0;
                margin: auto;
                margin-top: 30px;
              }
            }
          }

          .views-field-field-issues-image {
            width: 100%;
            padding: 0 25px 25px;
            img {
              width: 100%;
              height: auto;
            }
          }

          .views-field-field-additional-resources {
            display: none;
          }
        }
      }
    }

    &.page-taxonomy {
      .vocabulary-issues {
        .state-heading {
          .state-heading-block {
            padding: 25px;
            height: initial;
            .field-type-text {
              position: relative;
              margin: 0;

            }

            .taxonomy-term-description {
              height: initial;
            }

            .download-btn {
              a {
                margin: auto;
                position: relative;
                bottom: 0;
              }
            }
          }
        }

        .related-news-list {
          width: 100%;
          margin-left: 0;
          height: initial;
        }

        .secondary-heading-block {
          width: 100%;

          .field-name-field-secundary-headline {
            width: initial;
          }
        }
      }

      .vocabulary-states {
        .state-heading {
          .state-heading-block {
            .download-btn {
              display: block;
              margin: 0 auto;
              max-width: 280px;

              a {
                left: 0;
                right: 0;
                margin: 0 auto 36px;
                position: relative;
                bottom: 0;
              }
            }
          }

          .state-video-block {
            width: 100%;
            margin-left: 0;
            margin-top: 54px;
            height: initial;
            float: left;

            iframe {
              width: 100%;
              height: 375px;
            }

            .state-video {
              a {
                position: relative;
                bottom: 0;
                margin: auto;
              }
            }
          }


        }

        .partners-links-wrapper {
          .field-item {
            width: 100%;
          }
        }
      }
    }

    &.page-pfcd-in-the-states {
      #container {
        #page {
          .main-wrap {
            .featured-wrapper {
              width: $page-width;
            }
          }
        }
      }

      .view-pfcd-in-the-states {
        .views-row {
          width: 100%;
          height: initial;

          @include clearfix;

          .views-field-name {
            width: 100%;
            float: left;

            a {
              margin: 38px auto;
              float: none;
            }
          }
        }
      }
    }
    &.page-taxonomy .field-name-field-quick-facts .field-item {
      float: none;
      max-width: none;
      margin-bottom: 65px;
    }

    &.page-search {
      #search-form {
        .form-item-keys input#edit-keys {
          width: 60%;
          margin-right: 10px;
        }
      }

      .region-sidebar-first {
        width: 100%;
        .chosen-container {
          margin-top: 45px;
        }
      }

      ol.search-results {
        padding-top: 150px;
      }
    }
  }
}

@media all and (max-width: 767px) {
  $page-width: 100%;

  body {
    div.section-title {
      width: $page-width;
      padding: 0 0 0 15px;
    }

    &.page-pfcd-in-the-states {
      #container {
        #page {
          .main-wrap {
            .featured-wrapper {
              width: $page-width;
            }
          }
        }
      }

      .view-pfcd-in-the-states {
        .views-row {
          width: 100%;
          height: initial;

          @include clearfix;

          .views-field-field-image {
            width: $page-width;

            img {
              width: 100%;
              height: auto;
            }
          }

          .views-field-name {
            width: 100%;
            float: left;

            a {
              margin: 38px auto;
              float: none;
            }
          }
        }
      }
    }

    &.page-taxonomy {
      .vocabulary-states {
        .state-heading {
          .state-heading-block {
            width: 100%;
            padding: 15px;
            height: initial;
          }

          .state-video-block {
            iframe {
              height: 170px;
            }
          }
        }

        .partners-links-wrapper,
        .co-chair-list {
          padding: 15px;

          .title p {
            display: block;
          }
        }

        .view-states-news {
          width: 100%;
        }
      }

      .vocabulary-issues {
        .state-heading {
          width: 100%;
          .state-heading-block {
            width: 100%;

            .field-type-text {
              .field-item {
                line-height: 34px;
              }
            }
          }
        }
        .secondary-heading-block .field-name-field-secondary-description {
          width: 100%;
        }
      }


    }

    &.page-search {
      #search-form {
        .form-wrapper {
          margin: auto;
          text-align: center;

          .form-item-keys input#edit-keys {
            width: 100%;
          }

          input[type="submit"] {
            margin-top: 26px;
            width: 242px;
          }
        }
      }

      ol.search-results {
        width: 100%;
        padding: 150px 0px 0px 0px;
      }

      .region-sidebar-first {
        select {
          @include button;
          text-transform: none;
          padding: 15px 20px;
          width: 260px;
          display: block;
          position: absolute;
          text-align: center;
          bottom: 52px;
          outline: none;
          border: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          margin-top: 105px !important;
          position: relative;
        }
      }
    }

    .item-list {
      .pager {
        li {
          padding: 5px;
        }
      }
    }
  }

  body.html.not-front.no-sidebars.page-node.page-node-.node-type-webform #container {
    #page .main-wrap {
      width: $page-width;

      #main {
        display: block;
        width: 100%;

        .region-content {
          width: 100%;
          .content {
            padding: 0 25px;

            form.webform-client-form {
              input#edit-submitted-organization, input#edit-submitted-title {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }


  body.page-node-.one-sidebar #container #page .main-wrap {
    width: $page-width;

    #block-views-resources-block-1 {
      width: 100%;
    }
  }
}

@media all and (max-width: 600px) {
  body.html.not-front.no-sidebars.page-node.page-node-.node-type-webform {
    #container div:nth-child(3).column-wrapper .region.region-columns {
      .block-views div.block-content {
        .view-content .views-row {
          width: 100%;
        }
      }
    }
  }
}

@media all and (max-width: 359px) {
  body{
    &.page-issues {
      .view-issues {
        .views-row {
          .views-field-name {
            a {
              font-size: 24px;
            }
          }

          .views-field-description {
            p {
              a {
                &.views-more-link {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
